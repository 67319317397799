import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducer from "./reducers/index";
import { loadState, saveState } from "./utils/localStorage";
import { ContextProvider } from "./contexts/ContextProvider";
import reportWebVitals from "./reportWebVitals";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

if (typeof window !== "undefined") {
  const initialState = loadState() || {
    page: 0,
    rowsPerPage: 10,
    drawer: false,
    expanded: true,
    selected: "panel1",
  };

  let composeEnhacers;
  if (process.env.NODE_ENV === "production") composeEnhacers = compose;
  else composeEnhacers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    reducer,
    initialState,
    composeEnhacers(applyMiddleware(thunk))
  );

  store.subscribe(() => {
    saveState(store.getState());
  });

  ReactDOM.render(
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Provider store={store}>
        <ContextProvider>
          <App />
        </ContextProvider>
      </Provider>
    </MuiPickersUtilsProvider>,
    document.getElementById("root")
  );
}

reportWebVitals();
