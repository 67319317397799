import React from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Sort,
  Filter,
  ExcelExport,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { connect } from "react-redux";
import { Header } from "../../../components";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../api";
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { encrypt } from "../../../utils/crypt";
import Swal from "sweetalert2";
import BackdropComponent from "../../../components/Backdrop";
import { FindInPageOutlined } from "@material-ui/icons";
import DeleteButton from "../../../assets/img/eliminar.png";

const Permissions = (props) => {
  const history = useNavigate();
  const { token, user, permission, groupId, userId } = props;

  const fileName = "permisos";

  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filtro, setFiltro] = useState([]);

  const ViewButton = (props) => {
    const [actions, setActions] = useState([]);

    const [openDialog, setOpenDialog] = useState(false);

    const action = () => {
      setOpenDialog(true);
    };

    const closeDialog = () => {
      setOpenDialog(false);
    };

    const getActions = async () => {
      const { data } = await axios.post(
        `/permission/getPermissions`,
        {
          id_modulos: props.modulosAcciones.id_modulos,
          id_grupos_usuarios: props.id_grupos_usuarios,
        },
        {
          headers: { "access-token": token },
        }
      );
      setActions(data.permissions);
    };

    useEffect((e) => {
      getActions();
    }, []);

    return (
      <>
        <Tooltip title="Ver acciones">
          <IconButton aria-label="actions" onClick={() => action()}>
            <Typography style={{ color: "#3C3C3C" }}>
              {props.count_acciones}
            </Typography>
            <FindInPageOutlined />
          </IconButton>
        </Tooltip>

        <Dialog
          open={openDialog}
          onClose={closeDialog}
          fullWidth={false}
          scroll={"body"}
          maxWidth={"xs"}
        >
          <DialogContent>
            <List>
              <ListItem>
                <ListItemIcon>
                  <b>N°</b>
                </ListItemIcon>
                <ListItemText>
                  <b>Acciones</b>
                </ListItemText>
              </ListItem>
              <Divider />
              {actions
                .sort((a, b) =>
                  a.modulosAcciones?.acciones?.nombre <
                  b.modulosAcciones?.acciones?.nombre
                    ? -1
                    : 1
                )
                .map((item, index) => (
                  <ListItem key={`accion${index}`}>
                    <ListItemIcon>{index + 1}</ListItemIcon>
                    <ListItemText>
                      {item.modulosAcciones?.acciones?.nombre}
                    </ListItemText>
                  </ListItem>
                ))}
            </List>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  const deleteButton = (props) => {
    const modalDelete = (id_grupos_usuarios, id_modulos, id) => {
      Swal.fire({
        text: "¿Está seguro que desea eliminar este permiso?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#9d2d39",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          sendDelete(id_grupos_usuarios, id_modulos, id);
        }
      });
    };

    const sendDelete = async (id_grupos_usuarios, id_modulos, id) => {
      setLoading(true);
      const { data } = await axios.delete(`/permission/${id_grupos_usuarios}`, {
        data: { id_modulos: id_modulos, id_usuarios: userId },
        headers: { "access-token": token },
      });
      setFiltro(permissions.filter((item) => item.id !== id));
      setLoading(false);
      Swal.fire({
        text: "Eliminado exitosamente.",
        icon: "success",
        showConfirmButton: false,
        timer: 3000,
      });
      getPermissions();
    };
    return (
      <img
        src={DeleteButton}
        style={{ margin: "0 auto", width: "2rem", cursor: "pointer" }}
        disabled={!permission.includes(4)}
        onClick={() => {
          modalDelete(
            props.id_grupos_usuarios,
            props.modulosAcciones.id_modulos,
            props.id
          );
        }}
      />
    );
  };

  const PermissionsGrid = [
    {
      headerText: "Módulo",
      textAlign: "Center",
      field: "modulosAcciones.modulos.nombre",
    },
    {
      field: "grupoUsuarios.nombre",
      headerText: "Grupo de usuario",
      textAlign: "Center",
    },
    {
      template: (props) => {
        return <ViewButton {...props} />;
      },
      headerText: "Ver",
      textAlign: "Center",
    },
    {
      template: deleteButton,
      headerText: "Eliminar",
      textAlign: "Center",
    },
  ];

  const addPermission = {
    align: "Left",
    click: () => {
      history(`/permissions/create`);
    },
    disabled: !permission.includes(2),
    id: "grid_245085904_1_add",
    overflow: "None",
    prefixIcon: "e-add",
    showTextOn: "Both",
    text: "Crear permisos",
    tooltipText: "Add",
    type: "Button",
    visible: true,
    width: "auto",
  };

  useEffect(() => {
    if (permission.includes(1)) {
      getPermissions();
    } else {
      history("/");
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPermissions = async () => {
    const { data } = await axios.post(
      `/permission/getPermissionsByUserGroup`,
      {},
      {
        headers: { "access-token": token },
      }
    );
    if (groupId === 1) {
      setPermissions(data?.permissions);
      setFiltro(data?.permissions);
    } else {
      const permissionsFiltered = data.permissions.filter(
        (item) => item.id_grupos_usuarios !== 1
      );
      setPermissions(permissionsFiltered);
      setFiltro(permissionsFiltered);
    }
  };

  const searchOptions = {
    fields: ["modulosAcciones.modulos.nombre","grupoUsuarios.nombre"],
    ignoreCase: true,
    ignoreAccent: true,
    operator: "contains",
    key: "",
  };

  const dataToExcel = async (data) => {
    // eslint-disable-next-line array-callback-return
    const arrayExcel = data?.map((item) => {
      return {
        "Grupo de usuario": item.grupoUsuarios?.nombre,
        "Módulo": item.modulosAcciones?.modulos?.nombre,
        "Acciones": item.count_acciones
      };
    });
    exportToExcel(arrayExcel);
  };

  const excelExport = {
    align: "Left",
    click: () => {
      dataToExcel(permissions);
    },
    disabled: !permission.includes(5),
    id: "grid_245085904_0_excelexport",
    overflow: "None",
    prefixIcon: "e-excelexport",
    showTextOn: "Both",
    text: "Exportar",
    tooltipText: "ExcelExport",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const exportToExcel = (jsonData) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    if (jsonData.length > 0) {
      const ws = XLSX.utils.json_to_sheet(jsonData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  const toolbarOptions = ["Search", addPermission, excelExport];

  const editing = { allowDeleting: true, allowEditing: true };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Configuración" title="Permisos" />
      <GridComponent
        dataSource={permissions}
        width="auto"
        locale="es-ES"
        allowPaging
        allowSorting
        pageSettings={{ pageCount: 10 }}
        searchSettings={searchOptions}
        editSettings={editing}
        allowTextWrap
        toolbar={toolbarOptions}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {PermissionsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Search, Page, Sort, Filter, Toolbar, ExcelExport]} />
      </GridComponent>
      <BackdropComponent loading={loading} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.token,
    user: state.user,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 5)
      .map((item) => item.modulosAcciones?.id_acciones),
    userId: state.user.id,
    groupId: state.user.id_grupos_usuarios,
  };
};
export default connect(mapStateToProps, null)(Permissions);
