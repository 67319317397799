import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";


const SimpleBarChart = ({data}) => {
  return (
    <ResponsiveContainer width="100%" aspect={2 / 1}>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name"/>
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Finalizado" fill="#9c2e38" />
        <Bar dataKey="En curso" fill="#c59d33" />
        <Bar dataKey="No Iniciado" fill="#404f56" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SimpleBarChart;
