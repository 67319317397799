import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setBreadcrumps } from "../../../actions";
import {
  makeStyles,
  TextField,
  Button,
  Grid,
  Paper,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Swal from "sweetalert2";
import Header from "../../../components/Header";
import Backdrop from "../../../components/Backdrop";
import axios from "../../../api";
import { decrypt } from "../../../utils/crypt";
import { useParams } from "react-router-dom";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Search,
  Page,
  Sort,
  Filter,
  ExcelExport,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function FormPrograms(props) {
  const { setBreadcrumps, userId, permission, token } = props;
  const params = useParams();
  const classes = useStyles();
  const [assignedTeachers, setAssignedTeachers] = useState([]);
  const [teachers, setDirectors] = useState([]);
  const [departments, setDepartments] = useState([]);
  //const [faculties, setFaculties] = useState([]);
  const fileName = "docentesPrograma";
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    nombre: "",
    codigo: "",
    vp_facultad: "",
    id_departamentos: "",
    id_docentes: "",
  });

  const deleteButton = (props) => {
    let _data = props.column.parent.props.dataSource;

    const modalDelete = (id) => {
      Swal.fire({
        text: "¿Está seguro que desea eliminar a este docente de este programa?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#9d2d39",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          sendDelete(id);
        }
      });
    };

    const sendDelete = async (id) => {
      setAssignedTeachers(_data.filter((e, index) => index != id));
      Swal.fire({
        text: "Eliminado exitosamente.",
        icon: "success",
        showConfirmButton: false,
        timer: 3000,
      });
    };

    return (
      <Button
        onClick={(e) => {
          modalDelete(props.index);
        }}
        style={{ backgroundColor: "#9d2d39", color: "white" }}
      >
        Eliminar
      </Button>
    );
  };

  const TeachersGrid = [
    {
      headerText: "Nombres",
      textAlign: "Center",
      field: "nombres",
    },
    {
      headerText: "Apellidos",
      textAlign: "Center",
      field: "apellidos",
    },
    {
      headerText: "Usuario",
      textAlign: "Center",
      field: "usuario",
    },
    {
      headerText: "Correo",
      textAlign: "Center",
      field: "email",
    },
    {
      template: deleteButton,
      headerText: "Eliminar",
      textAlign: "Center",
    },
  ];

  const searchOptions = {
    fields: ["nombres", "apellidos", "usuario", "correo"],
    ignoreCase: true,
    ignoreAccent: true,
    operator: "contains",
    key: "",
  };

  useEffect(() => {
    if (permission.includes(2) || permission.includes(3)) {
      getTeachers();
      getDeparments();
      //getFaculties();
      if (params.id) {
        getGroup();
      }
    } else {
      history("/");
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGroup = async () => {
    const id = decrypt(params.id);
    const { data } = await axios.get(`/program/${id}`, {
      headers: { "access-token": token },
    });
    setAssignedTeachers(data?.program?.docentes?.map((e) => e.docente) || []);
    setForm(data.program);
  };

  const getTeachers = async () => {
    const { data } = await axios.post(
      `/user/getUsers`,
      {
        id_grupos_usuarios: 4,
      },
      {
        headers: { "access-token": token },
      }
    );
    setDirectors(data.users);
  };

  const getFaculties = async () => {
    const { data } = await axios.post(
      `/parameter/getParameters`,
      {
        id: [4],
      },
      {
        headers: { "access-token": token },
      }
    );
    setFaculties(data.parameters[0].valoresParametros);
  };

  const getDeparments = async () => {
    const { data } = await axios.post(
      `/department/getDepartments`,
      {},
      {
        headers: { "access-token": token },
      }
    );
    setDepartments(data.departments);
  };

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleCancel = () => {
    history("/programs");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!params.id) {
      let _form = { ...form };
      delete _form["id_docentes"];
      _form["teachers"] = assignedTeachers;
      axios
        .post(
          `/program/`,
          { ..._form, userId },
          {
            headers: { "access-token": token },
          }
        )
        .then((res) => {
          setLoading(false);
          history("/programs");
          Swal.fire({
            icon: "success",
            text: "Creado exitosamente.",
            showConfirmButton: false,
            timer: 3000,
          });
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            text: "No se ha podido crear.",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    } else {
      let _form = { ...form };
      delete _form["id_docentes"];
      _form["teachers"] = assignedTeachers;
      const id = decrypt(params.id);
      axios
        .put(
          `/program/${id}`,
          { ..._form, userId },
          {
            headers: { "access-token": token },
          }
        )
        .then((res) => {
          setLoading(false);
          history("/programs");
          Swal.fire({
            icon: "success",
            text: "Editado exitosamente.",
            showConfirmButton: false,
            timer: 3000,
          });
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            text: "No se ha podido editar.",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    }
  };

  const dataToExcel = async (data) => {
    // eslint-disable-next-line array-callback-return
    const arrayExcel = data?.map((item) => {
      return {
        Nombres: item.nombres,
        Apellidos: item.apellidos,
        Correo: item.correo,
        Codigo: item.codigo,
      };
    });
    exportToExcel(arrayExcel);
  };
  const exportToExcel = (jsonData) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    if (jsonData.length > 0) {
      const ws = XLSX.utils.json_to_sheet(jsonData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  const excelExport = {
    align: "Left",
    click: () => {
      dataToExcel(assignedTeachers);
    },
    disabled: !permission.includes(5),
    id: "grid_245085904_0_excelexport",
    overflow: "None",
    prefixIcon: "e-excelexport",
    showTextOn: "Both",
    text: "Exportar",
    tooltipText: "ExcelExport",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const addAssignedTeacher = (e) => {
    e.preventDefault();
    if (form.id_docentes !== "") {
      let assignedTeacher = teachers.filter(
        (e) => e.id === form.id_docentes
      )[0];
      setAssignedTeachers([...assignedTeachers, assignedTeacher]);
      setForm({ ...form, id_docentes: "" });
    } else {
      Swal.fire({
        icon: "error",
        text: "Por favor, seleccione a un profesor para poder añadirlo al programa",
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };

  const removeAssignedTeacher = (id) => {
    setAssignedTeachers(assignedTeachers.map((e) => e.id !== id));
  };

  const toolbarOptions = ["Search", excelExport];

  const editing = { allowDeleting: true, allowEditing: false };

  return (
    <>
      <Paper elevation={3}>
        <Divider />
        <div className={classes.paper}>
          <div className={classes.container}>
            <Header
              category="Parametrización evaluación docente"
              title={`${params.id ? "Editar" : "Crear"} programa`}
            />
            <form className={classes.root} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <TextField
                    required
                    fullWidth
                    label="Nombre"
                    name="nombre"
                    value={form.nombre}
                    variant="outlined"
                    onChange={handleInput}
                    InputProps={{
                      classes: {
                        root: classes.container__input_root,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <TextField
                    required
                    fullWidth
                    label="Código"
                    name="codigo"
                    value={form.codigo}
                    variant="outlined"
                    onChange={handleInput}
                    InputProps={{
                      classes: {
                        root: classes.container__input_root,
                      },
                    }}
                  />
                </Grid>
                {/*<Grid item xs={12}>
                  <FormControl required fullWidth variant="outlined">
                    <InputLabel id="facultyLabel">Facultad</InputLabel>
                    <Select
                      labelId="facultyLabel"
                      label="Facultad"
                      value={form.vp_facultad}
                      onChange={handleInput}
                      name="vp_facultad"
                      className={classes.container__input_root}
                    >
                      <MenuItem value="" disabled>
                        <em>Seleccione una opción</em>
                      </MenuItem>
                      {faculties
                        ?.sort((a, b) =>
                          `${a.valor_parametro}` < `${b.valor_parametro}`
                            ? -1
                            : 1
                        )
                        .map((data) => {
                          return (
                            <MenuItem key={`users-${data.id}`} value={data.id}>
                              {data.valor_parametro}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                    </Grid>*/}
                <Grid item xs={12}>
                  <FormControl required fullWidth variant="outlined">
                    <InputLabel id="departmentLabel">Departamento</InputLabel>
                    <Select
                      labelId="departmentLabel"
                      label="Departamento"
                      value={form.id_departamentos}
                      onChange={handleInput}
                      name="id_departamentos"
                      className={classes.container__input_root}
                    >
                      <MenuItem value="" disabled>
                        <em>Seleccione una opción</em>
                      </MenuItem>
                      {departments
                        ?.sort((a, b) =>
                          `${a.nombre}` < `${b.nombre}` ? -1 : 1
                        )
                        .map((data) => {
                          return (
                            <MenuItem key={`users-${data.id}`} value={data.id}>
                              {data.nombre}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="docentesLabel">Docentes</InputLabel>
                    <Select
                      labelId="docentesLabel"
                      label="Docentes"
                      value={form.id_docentes}
                      onChange={handleInput}
                      name="id_docentes"
                      className={classes.container__input_root}
                    >
                      <MenuItem value="" disabled>
                        <em>Seleccione una opción</em>
                      </MenuItem>
                      {teachers
                        ?.filter(
                          (e) =>
                            !assignedTeachers.some((e2) => e?.id === e2?.id)
                        )
                        .sort((a, b) =>
                          `${a.nombres} ${a.apellidos}` <
                          `${b.nombres} ${b.apellidos}`
                            ? -1
                            : 1
                        )
                        .map((data) => {
                          return (
                            <MenuItem key={`users-${data.id}`} value={data.id}>
                              {data.nombres} {data.apellidos}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    onClick={addAssignedTeacher}
                  >
                    Añadir profesor asignado
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <GridComponent
                    dataSource={assignedTeachers}
                    width="auto"
                    locale="es-ES"
                    allowPaging
                    allowSorting
                    pageSettings={{ pageCount: 10 }}
                    searchSettings={searchOptions}
                    editSettings={editing}
                    allowTextWrap
                    toolbar={toolbarOptions}
                  >
                    <ColumnsDirective>
                      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                      {TeachersGrid.map((item, index) => (
                        <ColumnDirective key={index} {...item} />
                      ))}
                    </ColumnsDirective>
                    <Inject
                      services={[
                        Search,
                        Page,
                        Sort,
                        Filter,
                        Toolbar,
                        ExcelExport,
                      ]}
                    />
                  </GridComponent>
                </Grid>
              </Grid>
              <div className={classes.containerButton}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  type="submit"
                >
                  Guardar
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={handleCancel}
                >
                  Cancelar
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Paper>
      <Backdrop loading={loading} />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "1em",
  },
  paper: {
    margin: theme.spacing(2, 2),
    paddingBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  container: {
    width: "80%",
  },
  containerButton: {
    marginTop: "1em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      marginTop: "1em",
      marginBottom: "1em",
    },
  },
  button: {
    margin: "0.5em",
    padding: ".5em 3em",
    borderRadius: "10px",
    backgroundColor: "#9d2d39",
    "&:hover": {
      backgroundColor: "#9d2d39",
    },
  },
  container__input_root: {
    borderRadius: "10px",
  },
}));

const mapStateToProps = (state) => {
  return {
    userId: state.user.id,
    token: state.token,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 11)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

const mapDispatchToProps = {
  setBreadcrumps,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormPrograms);
