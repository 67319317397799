import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setBreadcrumps } from "../../../actions";
import {
  makeStyles,
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Swal from "sweetalert2";
import Header from "../../../components/Header";
import Backdrop from "../../../components/Backdrop";
import axios from "../../../api";
import { decrypt } from "../../../utils/crypt";
import { useParams } from "react-router-dom";
import { DateTimePicker } from "@material-ui/pickers";

function FormQuestions(props) {
  const { setBreadcrumps, userId, permission, token } = props;
  const params = useParams();
  const classes = useStyles();
  const [skills, setSkills] = useState([]);
  const [measurementScale, setMeasurementScale] = useState([]);
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    pregunta: "",
    id_competencias:"",
    id_escalamedicion:""
  });

  useEffect(() => {
    if (permission.includes(2) || permission.includes(3)) {
      getMeasurementScale();
      getSkills();
      if (params.id) {
        getGroup();
      }
    } else {
      history("/");
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGroup = async () => {
    const id = decrypt(params.id);
    const { data } = await axios.get(`/question/${id}`, {
      headers: { "access-token": token },
    });
    setForm(data.question);
  };

  const getSkills = async () => {
    const { data } = await axios.post(
      `/skill/getSkills`,
      {},
      {
        headers: { "access-token": token },
      }
    );
    setSkills(data?.skills);
  };

  const getMeasurementScale = async () => {
    const { data } = await axios.post(
      `/measurementScale/getMeasurementScales`,
      {},
      {
        headers: { "access-token": token },
      }
    );
    setMeasurementScale(data?.measurementScale);
  };

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleCancel = () => {
    history("/questions");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!params.id) {
      axios
        .post(
          `/question/`,
          { ...form, userId },
          {
            headers: { "access-token": token },
          }
        )
        .then((res) => {
          setLoading(false);
          history("/questions");
          Swal.fire({
            icon: "success",
            text: "Creado exitosamente.",
            showConfirmButton: false,
            timer: 3000,
          });
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            text: "No se ha podido crear.",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    } else {
      const id = decrypt(params.id);
      axios
        .put(
          `/question/${id}`,
          { ...form, userId },
          {
            headers: { "access-token": token },
          }
        )
        .then((res) => {
          setLoading(false);
          history("/questions");
          Swal.fire({
            icon: "success",
            text: "Editado exitosamente.",
            showConfirmButton: false,
            timer: 3000,
          });
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            text: "No se ha podido editar.",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    }
  };

  return (
    <>
      <Paper elevation={3}>
        <Divider />
        <div className={classes.paper}>
          <div className={classes.container}>
            <Header
              category="Parametrización evaluación docente"
              title={`${params.id ? "Editar" : "Crear"} pregunta`}
            />
            <form className={classes.root} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <TextField
                    required
                    fullWidth
                    label="Pregunta"
                    name="pregunta"
                    value={form.pregunta}
                    variant="outlined"
                    onChange={handleInput}
                    InputProps={{
                      classes: {
                        root: classes.container__input_root,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl required fullWidth variant="outlined">
                    <InputLabel id="skillLabel">Competencia</InputLabel>
                    <Select
                      labelId="skillLabel"
                      label="Competencia"
                      value={form.id_competencias}
                      onChange={handleInput}
                      name="id_competencias"
                      className={classes.container__input_root}
                    >
                      <MenuItem value="" disabled>
                        <em>Seleccione una opción</em>
                      </MenuItem>
                      {skills
                        ?.sort((a, b) =>
                          `${a.orden}` < `${b.orden}` ? -1 : 1
                        )
                        .map((data) => {
                          return (
                            <MenuItem key={`skill-${data.id}`} value={data.id}>
                              {data.nombre}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl required fullWidth variant="outlined">
                    <InputLabel id="measurementScaleLabel">
                      Escala de medición
                    </InputLabel>
                    <Select
                      labelId="measurementScaleLabel"
                      label="Escala de medición"
                      value={form.id_escalamedicion}
                      onChange={handleInput}
                      name="id_escalamedicion"
                      className={classes.container__input_root}
                    >
                      <MenuItem value="" disabled>
                        <em>Seleccione una opción</em>
                      </MenuItem>
                      {measurementScale
                        ?.sort((a, b) =>
                          `${a.nombre}` < `${b.nombre}` ? -1 : 1
                        )
                        .map((data) => {
                          return (
                            <MenuItem
                              key={`measurementScale-${data.id}`}
                              value={data.id}
                            >
                              {data.nombre}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <div className={classes.containerButton}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  type="submit"
                >
                  Guardar
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={handleCancel}
                >
                  Cancelar
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Paper>
      <Backdrop loading={loading} />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "1em",
  },
  paper: {
    margin: theme.spacing(2, 2),
    paddingBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  container: {
    width: "80%",
  },
  containerButton: {
    marginTop: "1em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      marginTop: "1em",
      marginBottom: "1em",
    },
  },
  button: {
    margin: "0.5em",
    padding: ".5em 3em",
    borderRadius: "10px",
    backgroundColor: "#9d2d39",
    "&:hover": {
      backgroundColor: "#9d2d39",
    },
  },
  container__input_root: {
    borderRadius: "10px",
  },
}));

const mapStateToProps = (state) => {
  return {
    userId: state.user.id,
    token: state.token,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 13)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

const mapDispatchToProps = {
  setBreadcrumps,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormQuestions);
