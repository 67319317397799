import React from 'react';

const Header = ({ category, title, className = "" }) => (
  <div className={`${className.trim().length > 0 ? className : ""} mb-7`}>
    <p className="text-lg text-gray-400">{category}</p>
    <p className="text-3xl font-extrabold tracking-tight text-slate-900">
      {title}
    </p>
  </div>
);

export default Header;
