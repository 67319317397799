import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setBreadcrumps } from "../../../actions";
import {
  makeStyles,
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Swal from "sweetalert2";
import Header from "../../../components/Header";
import Backdrop from "../../../components/Backdrop";
import axios from "../../../api";
import { decrypt } from "../../../utils/crypt";
import { useParams } from "react-router-dom";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Search,
  Page,
  Sort,
  Filter,
  ExcelExport,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function FormSurveys(props) {
  const { setBreadcrumps, userId, permission, token } = props;
  const params = useParams();
  const classes = useStyles();
  const [assignedQuestions, setAssignedQuestions] = useState([]);
  const [surveyTypes, setSurveyTypes] = useState([]);
  //const [faculties, setFaculties] = useState([]);
  const fileName="preguntasEncuesta";
  const history = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    id_preguntas: "",
  });

  const deleteButton = (props) => {
    let _data = props.column.parent.props.dataSource;

    const modalDelete = (id) => {
      Swal.fire({
        text: "¿Está seguro que desea eliminar a este docente de este programa?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#9d2d39",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          sendDelete(id);
        }
      });
    };

    const sendDelete = async (id) => {
      setAssignedQuestions(_data.filter((e) => e.id !== id));
      Swal.fire({
        text: "Eliminado exitosamente.",
        icon: "success",
        showConfirmButton: false,
        timer: 3000,
      });
    };

    return (
      <Button
        onClick={(e) => {
          modalDelete(props.id);
        }}
        style={{ backgroundColor: "#9d2d39", color: "white" }}
      >
        Eliminar
      </Button>
    );
  };

  const TeachersGrid = [
    {
      headerText: "Pregunta",
      textAlign: "Center",
      field: "pregunta",
    },
    {
      headerText: "Competencia",
      textAlign: "Center",
      field: "competencia.nombre",
    },
    {
      template: deleteButton,
      headerText: "Eliminar",
      textAlign: "Center",
    },
  ];

  const searchOptions = {
    fields: ["pregunta","competencia.nombre"],
    ignoreCase: true,
    ignoreAccent: true,
    operator: "contains",
    key: "",
  };

  useEffect(() => {
    if (permission.includes(2) || permission.includes(3)) {
      getTiposEncuesta();
      getQuestions();
      if (params.id) {
        getSurveys();
      }
    } else {
      history("/");
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSurveys = async () => {
    const id = decrypt(params.id);
    const { data } = await axios.get(`/survey/${id}`, {
      headers: { "access-token": token },
    });
    setAssignedQuestions(data?.survey?.detalleEncuestas?.map((e) => e.preguntas) || []);
    setForm({...data.survey, id_preguntas:""});
  };

  const getQuestions = async () => {
    const { data } = await axios.post(
      `/question/getQuestions`,
      {
        estado:1
      },
      {
        headers: { "access-token": token },
      }
    );
    setQuestions(data.questions);
  };

  const getTiposEncuesta = async () => {
    const { data } = await axios.post(
      `/parameter/getParameters`,
      { id: 6 },
      {
        headers: { "access-token": token },
      }
    );
    setSurveyTypes(data.parameters[0].valoresParametros);
  };

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleCancel = () => {
    history("/surveys");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!params.id) {
      let _form = { ...form };
      delete _form["id_preguntas"];
      _form["preguntas"] = assignedQuestions;
      axios
        .post(
          `/survey/`,
          { ..._form, userId },
          {
            headers: { "access-token": token },
          }
        )
        .then((res) => {
          setLoading(false);
          history("/questions");
          Swal.fire({
            icon: "success",
            text: "Creado exitosamente.",
            showConfirmButton: false,
            timer: 3000,
          });
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            text: "No se ha podido crear.",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    } else {
      let _form = { ...form };
      delete _form["id_preguntas"];
      _form["preguntas"] = assignedQuestions;
      const id = decrypt(params.id);
      axios
        .put(
          `/survey/${id}`,
          { ..._form, userId },
          {
            headers: { "access-token": token },
          }
        )
        .then((res) => {
          setLoading(false);
          history("/surveys");
          Swal.fire({
            icon: "success",
            text: "Editado exitosamente.",
            showConfirmButton: false,
            timer: 3000,
          });
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            text: "No se ha podido editar.",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    }
  };

  const dataToExcel = async (data) => {
    // eslint-disable-next-line array-callback-return
    const arrayExcel = data?.map((item) => {
      return {
        Nombres: item.nombres,
        Apellidos: item.apellidos,
        Correo: item.correo,
        Codigo: item.codigo,
      };
    });
    exportToExcel(arrayExcel);
  };
  const exportToExcel = (jsonData) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    if (jsonData.length > 0) {
      const ws = XLSX.utils.json_to_sheet(jsonData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  const excelExport = {
    align: "Left",
    click: () => {
      dataToExcel(assignedTeachers);
    },
    disabled: !permission.includes(5),
    id: "grid_245085904_0_excelexport",
    overflow: "None",
    prefixIcon: "e-excelexport",
    showTextOn: "Both",
    text: "Exportar",
    tooltipText: "ExcelExport",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const addAssignedTeacher = (e) => {
    e.preventDefault();
    if (form.id_preguntas !== "") {
      let assignedQuestion = questions.filter(
        (e) => e.id === form.id_preguntas
      )[0];
      setAssignedQuestions([assignedQuestion,...assignedQuestions]);
      setForm({ ...form, id_preguntas: "" });
    } else {
      Swal.fire({
        icon: "error",
        text: "Por favor, seleccione una pregunta para poder añadirla a la encuesta",
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };

  const removeAssignedTeacher = (id) => {
    setAssignedTeachers(assignedTeachers.map((e) => e.id !== id));
  };

  const toolbarOptions = ["Search", excelExport];

  const editing = { allowDeleting: true, allowEditing: false };

  return (
    <>
      <Paper elevation={3}>
        <Divider />
        <div className={classes.paper}>
          <div className={classes.container}>
            <Header
              category="Parametrización evaluación docente"
              title={`${params.id ? "Editar" : "Crear"} encuesta`}
            />
            <form className={classes.root} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Typography variant="h6" container="h1">Tipo encuesta: {surveyTypes.filter((e) => e.id == form?.vp_tipo_encuesta)[0]?.valor_parametro || "Cargando..."}</Typography>
                </Grid>
                {/*<Grid item xs={12}>
                  <FormControl required fullWidth variant="outlined">
                    <InputLabel id="facultyLabel">Facultad</InputLabel>
                    <Select
                      labelId="facultyLabel"
                      label="Facultad"
                      value={form.vp_facultad}
                      onChange={handleInput}
                      name="vp_facultad"
                      className={classes.container__input_root}
                    >
                      <MenuItem value="" disabled>
                        <em>Seleccione una opción</em>
                      </MenuItem>
                      {faculties
                        ?.sort((a, b) =>
                          `${a.valor_parametro}` < `${b.valor_parametro}`
                            ? -1
                            : 1
                        )
                        .map((data) => {
                          return (
                            <MenuItem key={`users-${data.id}`} value={data.id}>
                              {data.valor_parametro}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                    </Grid>*/}
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="preguntasLabel">Preguntas</InputLabel>
                    <Select
                      labelId="preguntasLabel"
                      label="Preguntas"
                      value={form.id_preguntas}
                      onChange={handleInput}
                      name="id_preguntas"
                      className={classes.container__input_root}
                    >
                      <MenuItem value="" disabled>
                        <em>Seleccione una opción</em>
                      </MenuItem>
                      {questions
                        ?.filter(
                          (e) =>
                            !assignedQuestions.some((e2) => e?.id === e2?.id)
                        )
                        .sort((a, b) =>
                          `${a.pregunta} - ${a.competencia.nombre}` <
                          `${b.pregunta} - ${a.competencia.nombre}`
                            ? -1
                            : 1
                        )
                        .map((data) => {
                          return (
                            <MenuItem key={`users-${data.id}`} value={data.id}>
                              {data.pregunta} - {data.competencia.nombre}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    onClick={addAssignedTeacher}
                  >
                    Añadir pregunta
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <GridComponent
                    dataSource={assignedQuestions}
                    width="auto"
                    locale="es-ES"
                    allowPaging
                    allowSorting
                    pageSettings={{ pageCount: 10 }}
                    searchSettings={searchOptions}
                    editSettings={editing}
                    allowTextWrap
                    toolbar={toolbarOptions}
                  >
                    <ColumnsDirective>
                      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                      {TeachersGrid.map((item, index) => (
                        <ColumnDirective key={index} {...item} />
                      ))}
                    </ColumnsDirective>
                    <Inject
                      services={[
                        Search,
                        Page,
                        Sort,
                        Filter,
                        Toolbar,
                        ExcelExport,
                      ]}
                    />
                  </GridComponent>
                </Grid>
              </Grid>
              <div className={classes.containerButton}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  type="submit"
                >
                  Guardar
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={handleCancel}
                >
                  Cancelar
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Paper>
      <Backdrop loading={loading} />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "1em",
  },
  paper: {
    margin: theme.spacing(2, 2),
    paddingBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  container: {
    width: "80%",
  },
  containerButton: {
    marginTop: "1em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      marginTop: "1em",
      marginBottom: "1em",
    },
  },
  button: {
    margin: "0.5em",
    padding: ".5em 3em",
    borderRadius: "10px",
    backgroundColor: "#9d2d39",
    "&:hover": {
      backgroundColor: "#9d2d39",
    },
  },
  container__input_root: {
    borderRadius: "10px",
  },
}));

const mapStateToProps = (state) => {
  return {
    userId: state.user.id,
    token: state.token,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 17)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

const mapDispatchToProps = {
  setBreadcrumps,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormSurveys);
