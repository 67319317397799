import { useState, useEffect } from "react";
import { MdOutlineExitToApp } from "react-icons/md";
import { CardCustom, Header } from "../../components";
import { logoutRequest } from "../../actions";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { encrypt } from "../../utils/crypt";
import log_eval from "../../assets/img/logo_eval.png";
import Swal from "sweetalert2";
import axios from "../../api";
import { Grid, makeStyles  } from "@material-ui/core";

const SelectTeacher = (props) => {
  const classes = useStyles();
  const {
    user = {
      id: 0,
      nombres: "",
      apellidos: "",
    },
    token = "",
    userId = 1,
    logoutRequest,
  } = props;

  const [listTeachers, setListTeachers] = useState([]);
  const [date, setDate] = useState(new Date());
  const history = useNavigate();

  const formatDate = (date = new Date()) => {
    let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    let month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    let year = date.getFullYear();
    let hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    let minutes =
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    let seconds =
      date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  useEffect(async () => {
    if (token.trim().length > 0) {
      getPeriodo();
    } else {
      history("/");
    }
  }, []);

  const getPeriodo = () => {
    try {
      axios
        .post(
          "/period/getPeriods",
          {
            estado: 1,
          },
          {
            headers: {
              "access-token": token,
            },
          }
        )
        .then(({ data }) => {
          const { periods, message } = data;
          if (periods.length > 0) {
            const limitPeriod = new Date(
              periods[periods.length - 1].fecha_fin
                .replace("T", " ")
                .replace("Z", " ")
            );
            if (limitPeriod < new Date()) {
              Swal.fire({
                icon: "error",
                text: "El periodo de evaluación no se encuentra activo.",
                showConfirmButton: true,
                confirmButtonColor: "rgb(157, 16, 4)",
              }).then(() => {
                logoutRequest();
                history("/");
              });
            } else {
              setDate(limitPeriod);
              getTeachersxClass();
            }
          } else {
            Swal.fire({
              icon: "error",
              text: message,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            text: "Error al consultar.",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        text: "Error al consultar.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const getTeachersxClass = () => {
    try {
      axios
        .get("/evaluations/getTeachersxClass/" + userId, {
          headers: { "access-token": token },
        })
        .then((result) => {
          const { group = [], evaluations = [] } = result.data.listTeachers;
          if (group.length > 0) {
            const listItem = [];
            group.forEach((item) => {
              item.grupoMateria.docentes.forEach((item2) => {
                const evaluation = evaluations.find(
                  (item3) =>
                    item3.id_grupo_materias_evaluado === item.grupoMateria.id &&
                    item3.id_usuario_evaluado === item2.usuario.id
                ) || { vp_estado: 324 };
                listItem.push({
                  nombres: item2.usuario.nombres,
                  apellidos: item2.usuario.apellidos,
                  materia: item.grupoMateria.nombre,
                  idEvaluacion: evaluation.id,
                  estado: evaluation.vp_estado,
                  idDocente: item2.usuario.id,
                  idEstudiante: item.usuario.id,
                  idMaterias: item.grupoMateria.id_materias,
                  idPeriodo: item.id_periodos,
                  idGrupoEstudiantesMaterias: item.id,
                  idGrupoMateria: item.grupoMateria.id,
                  idPrograma: item.grupoMateria.materias.programa.id,
                });
              });
            });
            setListTeachers(listItem);
          } else {
            Swal.fire({
              icon: "error",
              text: "No se encontró las materias.",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          Swal.fire({
            icon: "error",
            text: "Error al consultar.",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        text: "Error al consultar.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const selectingTeacher = (props) => {
    const {
      idDocente,
      idEstudiante,
      idGrupoMateria,
      idMaterias,
      idPeriodo,
      idEvaluacion,
      idPrograma,
      estado,
    } = props;
    try {
      if (estado !== 323) {
        const data = {
          idDocente,
          idEstudiante,
          idGrupoMateria,
          idMaterias,
          idPeriodo,
          idPrograma,
          fechaInicio: new Date(),
        };
        axios
          .post(
            "evaluations/salveEvaluation",
            { data },
            {
              headers: { "access-token": token },
            }
          )
          .then(({ data }) => {
            const result = data.respuesta.id;
            history(`/selectingTeacher/${encrypt(result)}`);
          });
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div
      style={{ backgroundColor: "#ECEAE4", height: "100%", minHeight: "100vh" }}
      className="pt-4"
    >
      <div className="flex container">
        <Header category="Evaluación de Docente" title="Selección de docente" />
        <button
          className="align-self-center offset-md-9 btn button_icons mb-2 p-1"
          title="Cerrar sesión"
          style={{ borderRadius: "50%" }}
          onClick={() => {
            logoutRequest();
            history("/");
          }}
        >
          <MdOutlineExitToApp size={30} />
        </button>
      </div>
      <div className="container">
        <div className="mb-4">
          <p className="fw-bold">
            Usuario:{" "}
            <span className="fw-normal">
              {user.nombres} {user.apellidos}
            </span>
          </p>
          <p className="fw-bold">
            Fecha Limite Del Periodo:{" "}
            <span className="fw-normal">{formatDate(date)}</span>
          </p>
        </div>
        <Grid container spacing={2}>
          {listTeachers.map((item, index) => (
            <div className={classes.card}>
              <CardCustom
                title={item.materia}
                details={`Profesor: ${item.nombres} ${item.apellidos ?? ''}`}
                estado={item.estado}
                onClick={() => {
                  selectingTeacher(item);
                }}
              />
            </div>
          ))}
        </Grid>
      </div>
      <footer
        className="col-12 container align-self-end"
        style={{ backgroundColor: "rgb(157, 16, 4)", marginTop: "25vh" }}
      >
        <img
          src={log_eval}
          alt="Logo Evaluación Docente"
          className="mt-4"
          style={{ backgroundColor: "rgb(155, 15, 2)" }}
        />
      </footer>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
      margin: "5px 10px 5px 10px",
      width:"30%",
      [theme.breakpoints.down(700)]: {
          margin: '0 0 8px 0',
          width:"100%",
      },
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.user,
    userId: state.user.id,
    token: state.token,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 10)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

const mapDispatchToprops = {
  logoutRequest,
};

export default connect(mapStateToProps, mapDispatchToprops)(SelectTeacher);
