import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setBreadcrumps } from "../../../actions";
import {
  makeStyles,
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Swal from "sweetalert2";
import Header from "../../../components/Header";
import Backdrop from "../../../components/Backdrop";
import axios from "../../../api";
import { decrypt } from "../../../utils/crypt";
import { useParams } from "react-router-dom";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Search,
  Page,
  Sort,
  Filter,
  ExcelExport,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function FormGradeGroups(props) {
  const { setBreadcrumps, userId, permission, token } = props;
  const ref = useRef({});
  const params = useParams();
  const classes = useStyles();
  const [assignedStudents, setAssignedStudents] = useState([]);
  const [assignedTeachers, setAssignedTeachers] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [pensums, setPensums] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [students, setStudents] = useState([]);
  const [grades, setGrades] = useState([]);
  //const [faculties, setFaculties] = useState([]);
  const fileName = "docentesPrograma";
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    nombre: "",
    es_virtual: "",
    id_materias: "",
    id_docentes: "",
    pensum: "",
    id_estudiantes: "",
    id_periodos: "",
    id_docentes_list:"",
    id_periodos_docentes:""
  });

  const deleteButton = (props) => {
    
    let _data = props.column.parent.props.dataSource;

    const modalDelete = (id) => {
      Swal.fire({
        text: "¿Está seguro que desea eliminar a este estudiante de este grupo de materias?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#9d2d39",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          sendDelete(id);
        }
      });
    };

    const sendDelete = async (id) => {
      setAssignedStudents(_data.filter((e,index) => index != id))
      Swal.fire({
        text: "Eliminado exitosamente.",
        icon: "success",
        showConfirmButton: false,
        timer: 3000,
      });
    };

    return (
      <Button
        onClick={(e) => {
          modalDelete(props.index);
        }}
        style={{ backgroundColor: "#9d2d39", color: "white" }}
      >
        Eliminar
      </Button>
    );
  };


  const deleteButtonTeacher = (props) => {
    
    let _data = props.column.parent.props.dataSource;

    const modalDelete = (id) => {
      Swal.fire({
        text: "¿Está seguro que desea eliminar a este docente de este grupo de materias?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#9d2d39",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          sendDelete(id);
        }
      });
    };

    const sendDelete = async (id) => {
      setAssignedTeachers(_data.filter((e,index) => index != id))
      ref.current.refresh()
      Swal.fire({
        text: "Eliminado exitosamente.",
        icon: "success",
        showConfirmButton: false,
        timer: 3000,
      });
    };

    return (
      <Button
        onClick={(e) => {
          modalDelete(props.index);
        }}
        style={{ backgroundColor: "#9d2d39", color: "white" }}
      >
        Eliminar
      </Button>
    );
  };

  

  const StudentsGrid = [
    {
      headerText: "Nombres",
      textAlign: "Center",
      field: "usuario.nombres",
    },
    {
      headerText: "Apellidos",
      textAlign: "Center",
      field: "usuario.apellidos",
    },
    {
      headerText: "Usuario",
      textAlign: "Center",
      field: "usuario.usuario",
    },
    {
      headerText: "Correo",
      textAlign: "Center",
      field: "usuario.email",
    },
    {
      headerText: "Periodo",
      textAlign: "Center",
      field: "periodo.nombre",
    },
    {
      template: deleteButton,
      headerText: "Eliminar",
      textAlign: "Center",
    },
  ];

  const TeachersGrid = [
    {
      headerText: "Nombres",
      textAlign: "Center",
      field: "usuario.nombres",
    },
    {
      headerText: "Apellidos",
      textAlign: "Center",
      field: "usuario.apellidos",
    },
    {
      headerText: "Usuario",
      textAlign: "Center",
      field: "usuario.usuario",
    },
    {
      headerText: "Correo",
      textAlign: "Center",
      field: "usuario.email",
    },
    {
      headerText: "Periodo",
      textAlign: "Center",
      field: "periodo.nombre",
    },
    {
      template: deleteButtonTeacher,
      headerText: "Eliminar",
      textAlign: "Center",
    },
  ];

  const searchOptions = {
    fields: [
      "usuario.nombres",
      "usuario.apellidos",
      "usuario.usuario",
      "usuario.correo",
      "periodo.nombre",
    ],
    ignoreCase: true,
    ignoreAccent: true,
    operator: "contains",
    key: "",
  };

  useEffect(() => {
    if (permission.includes(2) || permission.includes(3)) {
      getTeachers();
      getGrades();
      getPeriods();
      getStudents();
      getPensums();
      //getFaculties();
      if (params.id) {
        getGroup();
      }
    } else {
      history("/");
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGroup = async () => {
    const id = decrypt(params.id);
    const { data } = await axios.get(`/gradeGroup/${id}`, {
      headers: { "access-token": token },
    });
    setAssignedStudents(data?.gradeGroup?.estudiantes || []);
    setAssignedTeachers(data?.gradeGroup?.docentes || []);
    setForm({ ...data.gradeGroup, id_estudiantes: "", id_grupos_usuarios: "", id_docentes_list:"", id_periodos_docentes:"", id_periodos:"" });
  };

  const getPeriods = async () => {
    const { data } = await axios.post(
      `/period/getPeriods`,
      {},
      {
        headers: { "access-token": token },
      }
    );
    setPeriods(data.periods);
  };

  const getTeachers = async () => {
    const { data } = await axios.post(
      `/user/getUsers`,
      {
        id_grupos_usuarios: 4,
      },
      {
        headers: { "access-token": token },
      }
    );
    setTeachers(data.users);
  };

  const getStudents = async () => {
    const { data } = await axios.post(
      `/user/getUsers`,
      {
        id_grupos_usuarios: 5,
      },
      {
        headers: { "access-token": token },
      }
    );
    setStudents(data.users);
  };

  const getPensums = async () => {
    const { data } = await axios.post(
      `/parameter/getParameters`,
      {
        id: [3],
      },
      {
        headers: { "access-token": token },
      }
    );
    setPensums(data.parameters[0].valoresParametros);
  };

  const getGrades = async () => {
    const { data } = await axios.post(
      `/grade/getGrades`,
      {},
      {
        headers: { "access-token": token },
      }
    );
    setGrades(data.grades);
  };

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleCancel = () => {
    history("/gradeGroups");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!params.id) {
      let _form = { ...form };
      delete _form["id_estudiantes"];
      _form["students"] = assignedStudents;
      _form['teachers'] = assignedTeachers;
      axios
        .post(
          `/gradeGroup/`,
          { ..._form, userId },
          {
            headers: { "access-token": token },
          }
        )
        .then((res) => {
          setLoading(false);
          history("/gradeGroups");
          Swal.fire({
            icon: "success",
            text: "Creado exitosamente.",
            showConfirmButton: false,
            timer: 3000,
          });
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            text: "No se ha podido crear.",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    } else {
      let _form = { ...form };
      delete _form["id_estudiantes"];
      _form["students"] = assignedStudents;
      _form['teachers'] = assignedTeachers;
      const id = decrypt(params.id);
      axios
        .put(
          `/gradeGroup/${id}`,
          { ..._form, userId },
          {
            headers: { "access-token": token },
          }
        )
        .then((res) => {
          setLoading(false);
          history("/gradeGroups");
          Swal.fire({
            icon: "success",
            text: "Editado exitosamente.",
            showConfirmButton: false,
            timer: 3000,
          });
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            text: "No se ha podido editar.",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    }
  };

  const dataToExcel = async (data) => {
    // eslint-disable-next-line array-callback-return
    const arrayExcel = data?.map((item) => {
      return {
        Nombres: item.nombres,
        Apellidos: item.apellidos,
        Correo: item.correo,
        Codigo: item.codigo,
      };
    });
    exportToExcel(arrayExcel);
  };
  const exportToExcel = (jsonData) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    if (jsonData.length > 0) {
      const ws = XLSX.utils.json_to_sheet(jsonData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  const excelExport = {
    align: "Left",
    click: () => {
      dataToExcel(assignedStudents);
    },
    disabled: !permission.includes(5),
    id: "grid_245085904_0_excelexport",
    overflow: "None",
    prefixIcon: "e-excelexport",
    showTextOn: "Both",
    text: "Exportar",
    tooltipText: "ExcelExport",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const addAssignedStudent = (e) => {
    e.preventDefault();
    if (form.id_estudiantes !== "" && form.id_periodos !== "") {
      let check = assignedStudents.filter(
        (e) =>
          e.usuario?.id === form.id_estudiantes &&
          e.periodo?.id === form.id_periodos
      );
      if (check.length > 0) {
        Swal.fire({
          icon: "error",
          text: "No se puede añadir un estudiante en el mismo periodo más de una vez, por favor verifique su entrada",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        let assignedStudent = students.filter(
          (e) => e.id === form.id_estudiantes
        )[0];
        let period = periods.filter((e) => e.id === form.id_periodos)[0];
        setAssignedStudents([
          ...assignedStudents,
          { usuario: assignedStudent, periodo: period },
        ]);
        setForm({ ...form, id_estudiantes: "" });
      }
    } else {
      Swal.fire({
        icon: "error",
        text: "Por favor, seleccione a un estudiante y un periodo para poder añadirlo al grupo de materias",
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };

  const addAssignedTeacher = (e) => {
    e.preventDefault();
    if (form.id_docentes_list !== "" && form.id_periodos_docentes !== "") {
      let check = assignedTeachers.filter(
        (e) =>
          e.usuario?.id === form.id_docentes_list &&
          e.periodo?.id === form.id_periodos_docentes
      );
      if (check.length > 0) {
        Swal.fire({
          icon: "error",
          text: "No se puede añadir un docente en el mismo periodo más de una vez, por favor verifique su entrada",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        let assignedTeacher = teachers.filter(
          (e) => e.id === form.id_docentes_list
        )[0];
        let period = periods.filter((e) => e.id === form.id_periodos_docentes)[0];
        setAssignedTeachers([
          ...assignedTeachers,
          { usuario: assignedTeacher, periodo: period },
        ]);
        setForm({ ...form, id_docentes_list: "" });
      }
    } else {
      Swal.fire({
        icon: "error",
        text: "Por favor, seleccione a un docente y un periodo para poder añadirlo al grupo de materias",
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };

  const removeAssignedStudent = (id) => {
    setAssignedStudents(assignedStudents.map((e) => e.id !== id));
  };
  const removeAssignedTeachers = (id) => {
    setAssignedTeachers(assignedTeachers.map((e) => e.id !== id));
  };

  const toolbarOptions = ["Search", excelExport];

  const editing = { allowDeleting: true, allowEditing: false };

  return (
    <>
      <Paper elevation={3}>
        <Divider />
        <div className={classes.paper}>
          <div className={classes.container}>
            <Header
              category="Parametrización evaluación docente"
              title={`${params.id ? "Editar" : "Crear"} grupo de materias`}
            />
            <form className={classes.root} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <TextField
                    required
                    fullWidth
                    label="Nombre"
                    name="nombre"
                    value={form.nombre}
                    variant="outlined"
                    onChange={handleInput}
                    InputProps={{
                      classes: {
                        root: classes.container__input_root,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl required fullWidth variant="outlined">
                    <InputLabel id="virtualLabel">¿Es virtual?</InputLabel>
                    <Select
                      labelId="virtualLabel"
                      label="¿Es virtual?"
                      value={form.es_virtual}
                      onChange={handleInput}
                      name="es_virtual"
                      className={classes.container__input_root}
                    >
                      <MenuItem value="" disabled>
                        <em>Seleccione una opción</em>
                      </MenuItem>
                      <MenuItem value="1">Sí</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl required fullWidth variant="outlined">
                    <InputLabel id="pensumLabel">Pensum</InputLabel>
                    <Select
                      labelId="pensumLabel"
                      label="Pensum"
                      value={form.pensum}
                      onChange={handleInput}
                      name="pensum"
                      className={classes.container__input_root}
                    >
                      <MenuItem value="" disabled>
                        <em>Seleccione una opción</em>
                      </MenuItem>
                      {pensums
                        ?.sort((a, b) =>
                          `${a.valor_parametro}` < `${b.valor_parametro}`
                            ? -1
                            : 1
                        )
                        .map((data) => {
                          return (
                            <MenuItem
                              key={`pensum-${data.valor_parametro}`}
                              value={data.valor_parametro}
                            >
                              {data.valor_parametro}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl required fullWidth variant="outlined">
                    <InputLabel id="gradeLabel">Materia</InputLabel>
                    <Select
                      labelId="gradeLabel"
                      label="Materia"
                      value={form.id_materias}
                      onChange={handleInput}
                      name="id_materias"
                      className={classes.container__input_root}
                    >
                      <MenuItem value="" disabled>
                        <em>Seleccione una opción</em>
                      </MenuItem>
                      {grades
                        ?.sort((a, b) =>
                          `${a.nombre}` < `${b.nombre}` ? -1 : 1
                        )
                        .map((data) => {
                          return (
                            <MenuItem key={`users-${data.id}`} value={data.id}>
                              {data.nombre}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="docentesLabel">Docente</InputLabel>
                    <Select
                      labelId="docentesLabel"
                      label="Docentes"
                      value={form.id_docentes}
                      onChange={handleInput}
                      name="id_docentes"
                      className={classes.container__input_root}
                    >
                      <MenuItem value="" disabled>
                        <em>Seleccione una opción</em>
                      </MenuItem>
                      {teachers
                        .sort((a, b) =>
                          `${a.nombres} ${a.apellidos}` <
                          `${b.nombres} ${b.apellidos}`
                            ? -1
                            : 1
                        )
                        .map((data) => {
                          return (
                            <MenuItem key={`users-${data.id}`} value={data.id}>
                              {data.nombres} {data.apellidos}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="estudiantesLabel">Estudiantes</InputLabel>
                    <Select
                      labelId="estudiantesLabel"
                      label="Estudiantes"
                      value={form.id_estudiantes}
                      onChange={handleInput}
                      name="id_estudiantes"
                      className={classes.container__input_root}
                    >
                      <MenuItem value="" disabled>
                        <em>Seleccione una opción</em>
                      </MenuItem>
                      {students
                        ?.filter(
                          (e) =>
                            !assignedStudents.some((e2) => e?.id === e2?.id)
                        )
                        .sort((a, b) =>
                          `${a.nombres} ${a.apellidos}` <
                          `${b.nombres} ${b.apellidos}`
                            ? -1
                            : 1
                        )
                        .map((data) => {
                          return (
                            <MenuItem key={`users-${data.id}`} value={data.id}>
                              {data.nombres} {data.apellidos}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="periodsLabel">Periodos</InputLabel>
                    <Select
                      labelId="periodsLabel"
                      label="Periodos"
                      value={form.id_periodos}
                      onChange={handleInput}
                      name="id_periodos"
                      className={classes.container__input_root}
                    >
                      <MenuItem value="" disabled>
                        <em>Seleccione una opción</em>
                      </MenuItem>
                      {periods
                        ?.sort((a, b) =>
                          `${a.nombre}` < `${b.nombre} ` ? -1 : 1
                        )
                        .map((data) => {
                          return (
                            <MenuItem key={`users-${data.id}`} value={data.id}>
                              {data.nombre}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    onClick={addAssignedStudent}
                  >
                    Añadir estudiante asignado
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <GridComponent
                    ref={(g) => (ref.current = g)}
                    dataSource={assignedStudents}
                    width="auto"
                    locale="es-ES"
                    allowPaging
                    allowSorting
                    pageSettings={{ pageCount: 10 }}
                    searchSettings={searchOptions}
                    editSettings={editing}
                    allowTextWrap
                    toolbar={toolbarOptions}
                  >
                    <ColumnsDirective>
                      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                      {StudentsGrid.map((item, index) => (
                        <ColumnDirective key={index} {...item} />
                      ))}
                    </ColumnsDirective>
                    <Inject
                      services={[
                        Search,
                        Page,
                        Sort,
                        Filter,
                        Toolbar,
                        ExcelExport,
                      ]}
                    />
                  </GridComponent>
                </Grid>
                <Grid item xs={8}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="docentesLabel">Docentes</InputLabel>
                    <Select
                      labelId="docentesLabel"
                      label="Docentes"
                      value={form.id_docentes_list}
                      onChange={handleInput}
                      name="id_docentes_list"
                      className={classes.container__input_root}
                    >
                      <MenuItem value="" disabled>
                        <em>Seleccione una opción</em>
                      </MenuItem>
                      {teachers
                        ?.filter(
                          (e) =>
                            !assignedTeachers.some((e2) => e?.id === e2?.id)
                        )
                        .sort((a, b) =>
                          `${a.nombres} ${a.apellidos}` <
                          `${b.nombres} ${b.apellidos}`
                            ? -1
                            : 1
                        )
                        .map((data) => {
                          return (
                            <MenuItem key={`users-${data.id}`} value={data.id}>
                              {data.nombres} {data.apellidos}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="periodsLabel">Periodos</InputLabel>
                    <Select
                      labelId="periodsLabel"
                      label="Periodos"
                      value={form.id_periodos_docentes}
                      onChange={handleInput}
                      name="id_periodos_docentes"
                      className={classes.container__input_root}
                    >
                      <MenuItem value="" disabled>
                        <em>Seleccione una opción</em>
                      </MenuItem>
                      {periods
                        ?.sort((a, b) =>
                          `${a.nombre}` < `${b.nombre} ` ? -1 : 1
                        )
                        .map((data) => {
                          return (
                            <MenuItem key={`users-${data.id}`} value={data.id}>
                              {data.nombre}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    onClick={addAssignedTeacher}
                  >
                    Añadir docente asignado
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <GridComponent
                    dataSource={assignedTeachers}
                    width="auto"
                    locale="es-ES"
                    allowPaging
                    allowSorting
                    pageSettings={{ pageCount: 10 }}
                    searchSettings={searchOptions}
                    editSettings={editing}
                    allowTextWrap
                    toolbar={toolbarOptions}
                  >
                    <ColumnsDirective>
                      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                      {TeachersGrid.map((item, index) => (
                        <ColumnDirective key={index} {...item} />
                      ))}
                    </ColumnsDirective>
                    <Inject
                      services={[
                        Search,
                        Page,
                        Sort,
                        Filter,
                        Toolbar,
                        ExcelExport,
                      ]}
                    />
                  </GridComponent>
                </Grid>
              </Grid>
              <div className={classes.containerButton}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  type="submit"
                >
                  Guardar
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={handleCancel}
                >
                  Cancelar
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Paper>
      <Backdrop loading={loading} />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "1em",
  },
  paper: {
    margin: theme.spacing(2, 2),
    paddingBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  container: {
    width: "80%",
  },
  containerButton: {
    marginTop: "1em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      marginTop: "1em",
      marginBottom: "1em",
    },
  },
  button: {
    margin: "0.5em",
    padding: ".5em 3em",
    borderRadius: "10px",
    backgroundColor: "#9d2d39",
    "&:hover": {
      backgroundColor: "#9d2d39",
    },
  },
  container__input_root: {
    borderRadius: "10px",
  },
}));

const mapStateToProps = (state) => {
  return {
    userId: state.user.id,
    token: state.token,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 15)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

const mapDispatchToProps = {
  setBreadcrumps,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormGradeGroups);
