// AuthCallback.js
import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

export const AuthCallback = () => {
    const { instance, accounts, inProgress } = useMsal();
    const navigate = useNavigate();

    useEffect(() => {
        /* console.log(instance,accounts,inProgress) */
        if (!inProgress && accounts.length > 0) {
           navigate('/users'); // Redirect to home after successful login
        }
    }, [accounts, inProgress, navigate]);

    return <div>Loading...</div>;
};