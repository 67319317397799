import React from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Sort,
  Filter,
  ExcelExport,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { connect } from "react-redux";
import { Header } from "../../../components";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../api";
import { Button } from "@material-ui/core";
import { encrypt } from "../../../utils/crypt";
import Swal from "sweetalert2";
import BackdropComponent from "../../../components/Backdrop";

const UserGroups = (props) => {
  const history = useNavigate();
  const { token, user, permission, groupId, userId } = props;

  const fileName = "grupos de usuario";

  const [users, setUserGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filtro, setFiltro] = useState([]);

  const editButton = (props) => {
    const history = useNavigate();
    const action = (e) => {
      history(`/userGroups/edit/${encrypt(props.id)}`);
    };
    return (
      <Button
        onClick={action}
        style={{ backgroundColor: "#9d2d39", color: "white" }}
        disabled={!permission.includes(3)}
      >
        Editar
      </Button>
    );
  };

  const deleteButton = (props) => {
    const modalDelete = (id) => {
      Swal.fire({
        text: "¿Está seguro que desea eliminar este grupo de usuario?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#9d2d39",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          sendDelete(id);
        }
      });
    };

    const sendDelete = async (id) => {
      setLoading(true);
      const { data } = await axios.delete(`/userGroup/${id}`, {
        data: { id_usuarios: userId },
        headers: { "access-token": token },
      });
      if (data.groupDeleted?.success) {
        setLoading(false);
        Swal.fire({
          text: "Eliminado exitosamente.",
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
        });
        
        getUserGroups();
      } else {
        setLoading(false);
        Swal.fire({
          text: data.userId?.message,
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    };
    return (
      <Button
        onClick={(e) => {
          modalDelete(props.id)
        }}
        disabled={!permission.includes(4)}
        style={{ backgroundColor: "#9d2d39", color: "white" }}
      >
        Eliminar
      </Button>
    );
  };

  const UserGroupsGrid = [
    {
      headerText: "Nombre",
      textAlign: "Center",
      field: "nombre",
    },
    {
      template: editButton,
      headerText: "Editar",
      textAlign: "Center",
    },
    {
      template: deleteButton,
      headerText: "Eliminar",
      textAlign: "Center",
    },
  ];

  const addUserGroup = {
    align: "Left",
    click: () => {
      history(`/userGroups/create`);
    },
    disabled: !permission.includes(2),
    id: "grid_245085904_1_add",
    overflow: "None",
    prefixIcon: "e-add",
    showTextOn: "Both",
    text: "Crear grupo de usuario",
    tooltipText: "Add",
    type: "Button",
    visible: true,
    width: "auto",
  };

  useEffect(() => {
    if (permission.includes(1)) {
      getUserGroups();
    } else {
      history("/");
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserGroups = async () => {
    const { data } = await axios.post(
      `/userGroup/getGroups`,
      {},
      {
        headers: { "access-token": token },
      }
    );
    setUserGroups(data?.groups);
    setFiltro(data?.groups);
  };

  const searchOptions = {
    fields: ["nombre"],
    ignoreCase: true,
    ignoreAccent: true,
    operator: "contains",
    key: "",
  };

  const dataToExcel = async (data) => {
    // eslint-disable-next-line array-callback-return
    const arrayExcel = data?.map((item) => {
      return {
        Nombre: item.nombre,
      };
    });
    exportToExcel(arrayExcel);
  };

  const excelExport = {
    align: "Left",
    click: () => {
      dataToExcel(users);
    },
    disabled: !permission.includes(5),
    id: "grid_245085904_0_excelexport",
    overflow: "None",
    prefixIcon: "e-excelexport",
    showTextOn: "Both",
    text: "Exportar",
    tooltipText: "ExcelExport",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const exportToExcel = (jsonData) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    if (jsonData.length > 0) {
      const ws = XLSX.utils.json_to_sheet(jsonData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  const toolbarOptions = ["Search", addUserGroup, excelExport];

  const editing = { allowDeleting: true, allowEditing: true };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Configuración" title="Grupos de usuario" />
      <GridComponent
        dataSource={users}
        width="auto"
        locale="es-ES"
        allowPaging
        allowSorting
        pageSettings={{ pageCount: 10 }}
        searchSettings={searchOptions}
        editSettings={editing}
        allowTextWrap
        toolbar={toolbarOptions}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {UserGroupsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Search, Page, Sort, Filter, Toolbar, ExcelExport]} />
      </GridComponent>
      <BackdropComponent loading={loading} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.token,
    user: state.user,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 1)
      .map((item) => item.modulosAcciones?.id_acciones),
    userId: state.user.id,
    groupId: state.user.id_grupos_usuarios,
  };
};
export default connect(mapStateToProps, null)(UserGroups);
