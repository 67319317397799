import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { loginRequest, setPermissions, setToken } from "../actions";
import {
  makeStyles,
  Grid,
  Button,
  CssBaseline,
  Paper,
  Typography,
  Link,
  TextField,
  FormControl,
  MenuItem,
  Select,
  FormLabel,
  InputLabel,
} from "@material-ui/core";
import axios from "../api";
import Swal from "sweetalert2";
import HomeBackground from "../assets/img/Login_BG.jpg";
import Backdrop from "../components/Backdrop";
import { useEffect } from "react";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

function SignIn(props) {
  const { user, loginRequest, setPermissions, setToken } = props;
  const history = useNavigate();
  const classes = useStyles();
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [authType, setAuthType] = useState("ldap");

  useEffect(() => {
    if (Object.keys(user || {}).length > 0) {
      if (user?.id_grupos_usuarios === 1 || user?.id_grupos_usuarios === 2) {
        history("/report");
      } else {
        history("/evaluation");
        window.location.reload();
      }
    }
  }, []);

  useEffect(() => {
    if (authType == "ldap") {
      setForm({
        ...form,
        usuario: "",
      });
    } else {
      setForm({
        ...form,
        email: "",
        password: "",
      });
    }
  }, [authType]);

  const { login, authenticationState, error} = useMsalAuthentication();

  console.log(authenticationState)

  if(error){
    console.log(error.message)
  }

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (authType == "ldap") {
      if (form.usuario == undefined || form.usuario == "") {
        Swal.fire({
          icon: "error",
          text: "Ingrese un usuario.",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        const result = await login(InteractionType.Popup, {
          scopes: ["User.Read","openid", "profile", "offline_access"],
          prompt: "login",
          loginHint: `${form.usuario}@cuc.edu.co`,
        });
        setLoading(true);
        axios
          .post(`/user/signin`, { accessToken: result.accessToken })
          .then((res) => {
            if (res.data.user.success) {
              loginRequest(res.data?.user?.user);
              setPermissions(res.data?.user?.permissions);
              setToken(res.data?.user?.token);
              if (
                res.data?.user?.user?.id_grupos_usuarios === 1 ||
                res.data?.user?.user?.id_grupos_usuarios === 2
              ) {
                history("/report");
              } else {
                history("/evaluation");
                window.location.reload();
              }
              setLoading(false);
            } else {
              Swal.fire({
                icon: "error",
                text: res.data?.user?.message,
                showConfirmButton: false,
                timer: 3000,
              });
              setLoading(false);
            }
          });
      }
    } else {
      setLoading(true);
      axios
        .post(`/user/signin`, form)
        .then((res) => {
          if (res.data.user.success) {
            loginRequest(res.data?.user?.user);
            setPermissions(res.data?.user?.permissions);
            setToken(res.data?.user?.token);
            if (
              res.data?.user?.user?.id_grupos_usuarios === 1 ||
              res.data?.user?.user?.id_grupos_usuarios === 2
            ) {
              history("/report");
            } else {
              history("/evaluation");
              window.location.reload();
            }
            setLoading(false);
          } else {
            Swal.fire({
              icon: "error",
              text: res.data?.user?.message,
              showConfirmButton: false,
              timer: 3000,
            });
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            text: "Usuario y/o contraseña incorrecta.",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} md={7} className={classes.image}>
        {/* <Typography component="h1" variant="h4" className={classes.textField}>
          Aprende a desarrollar mejores prácticas para el Aula
        </Typography> */}
      </Grid>
      <Grid item xs={12} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <div className={classes.containerLogo}>
            {/* <img alt="logo" src={Logo} /> */}
          </div>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h4"
                style={{ fontWeight: "bolder", letterSpacing: "-1px" }}
              >
                Acceder
              </Typography>
            </Grid>
          </Grid>
          <form className={classes.form} onSubmit={handleSubmit}>
            <FormControl required fullWidth variant="outlined">
              <InputLabel id="authLabel">Tipo de Autenticación</InputLabel>
              <Select
                labelId="authLabel"
                label="Tipo de Autenticación"
                value={authType}
                onChange={(e) => setAuthType(e.target.value)}
                inputProps={{
                  name: "authType",
                  id: "auth-type-input",
                }}
              >
                <MenuItem value={"ldap"}>Institucional</MenuItem>
                <MenuItem value={"local"}>Administrativo</MenuItem>
              </Select>
            </FormControl>
            {authType == "ldap" && (
              <TextField
                required
                fullWidth
                variant="outlined"
                margin="normal"
                label="Usuario"
                name="usuario"
                autoComplete="usuario"
                value={form.usuario}
                type="usuario"
                onChange={handleInput}
                InputLabelProps={{
                  classes: {
                    root: classes.color,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.container__input_root,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            )}
            {authType == "local" && (
              <>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  label="Correo electrónico"
                  name="email"
                  autoComplete="email"
                  type="email"
                  onChange={handleInput}
                  InputLabelProps={{
                    classes: {
                      root: classes.color,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  key="Contraseña"
                  label="Contraseña"
                  name="password"
                  autoComplete="current-password"
                  type="password"
                  onChange={handleInput}
                  InputLabelProps={{
                    classes: {
                      root: classes.color,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.container__input_root,
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
              </>
            )}
            <Button
              color="primary"
              type="submit"
              variant="contained"
              className={classes.submit}
            >
              Iniciar sesión
            </Button>
          </form>
        </div>
      </Grid>
      <Backdrop loading={loading} />
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  anchor: {
    color: "#9d2d39",
    "&:hover": {
      color: "#9d2d39",
    },
  },
  image: {
    backgroundImage: `url(${HomeBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    margin: theme.spacing(16, 6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(17, 6),
    },
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    width: "13em",
    fontWeight: "bolder",
    borderRadius: "10px",
    backgroundColor: "#9d2d39",
    "&:hover": {
      backgroundColor: "#9d2d39",
    },
  },
  container__input_root: {
    borderRadius: "10px",
  },
  containerLogo: {
    margin: "-3em 0 3em 0",
  },
  textField: {
    margin: "11em 1em 0 1em",
    color: theme.palette.background.main,
    fontWeight: "bolder",
    fontSize: "42px",
    textShadow: "2px 2px 2px black",
    display: "none",
  },
  notchedOutline: {
    borderColor: "#9d2d39 !important",
  },
  color: {
    color: "#9d2d39 !Important",
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToprops = {
  loginRequest,
  setPermissions,
  setToken,
};

export default connect(mapStateToProps, mapDispatchToprops)(SignIn);
